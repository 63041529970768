
import { PartRequest } from '@/store/modules';
import { ErrorInterface } from '@/utility';
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export class PartRequestStoreError extends Vue {
  subscription: Array<() => void> = [];

  destroyed() {
    this.subscription.forEach((fn) => fn());
  }

  created() {
    const update = this.$store.watch<ErrorInterface[]>(
      () => PartRequest.getErrors(),
      (val, oldVal) => {
        if (val && val.length) {
          this.$root.$bvToast.toast(`Failed to update or retrieve part request`, {
            title: "Error",
            variant: "danger",
          });
        }
      }
    );
    // const saving = this.$store.watch<boolean>(
    //   () => PartRequest.isSaving(),
    //   (val) => {
    //     this.saving = val;
    //   }
    // );
    this.subscription = [update];
  }
}

export default PartRequestStoreError;
