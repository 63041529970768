
import { CDataTableFields } from "@/@types";
import { CurrencyFormatMixin, DateFormatMixin, HeMixin } from "@/mixins";
import { LabourRate, LabourRateFilterQueryPayload } from "@/store/modules";
import { mixins } from "vue-class-component";
import { Component, Prop, PropSync } from "vue-property-decorator";

@Component({
  components: {},
})
export class LabourRateList extends mixins(DateFormatMixin, HeMixin, CurrencyFormatMixin) {
  @Prop({ default: () => [] })
  private items!: LabourRate[];

  @Prop({ default: () => false })
  private loading!: boolean;

  @Prop({ default: () => 0 })
  private total!: boolean;

  @Prop({ default: () => true })
  private itemsPerPageSelect!: boolean;

  @PropSync("itemsPerPage", { default: () => 25, type: Number })
  itemsPerPageSync!: number;

  @PropSync("columnFilterValue", { default: () => ({}), type: Object })
  columnFilterValueSync!: LabourRateFilterQueryPayload;

  @PropSync("sorterValue", { default: () => ({}), type: Object })
  sorterValueSync!: { column: string | null; asc: string | boolean };

  showVoidModal = false;

  selectedVoid: LabourRate | null = null;

  page = 1;

  fields: CDataTableFields = [
    { key: "rate", label: "Rate", sorter: false, filter: false },
    { key: "tech_name", label: "Changed by", sorter: false, filter: false },
    { key: "comment", label: "Comment", sorter: false, filter: false },
    { key: "created_at", label: "Updated", sorter: false, filter: false },
    { key: "voided_at", label: "Voided", sorter: false, filter: false },
    { key: "action", label: "", filter: false, sorter: false },
  ];

  savingText = "Saving";
  saving = false;
  error: string | null = null;
  voidMessage = '';

  onShowVoidMessage(item: LabourRate) {
    this.$bvModal.msgBoxOk(item.voided_comment || 'No message', {
      title: "Reason",

    });
  }

  onCloseModal() {
    this.selectedVoid = null;
    this.$nextTick(() => this.showVoidModal = false);

  }

  toggleModal() {
    if (!this.showVoidModal) {
      this.selectedVoid = null;
    }
    this.showVoidModal = !this.showVoidModal;
  }

  onVoid(item: LabourRate) {
    this.selectedVoid = item;
    this.$nextTick(() => this.showVoidModal = true);

  }

  onViewComment(item: LabourRate) {
    this.$bvModal.msgBoxOk(item.comment || 'No comment', {
      title: "Reason",
    });
  }

  async onVoidSubmit() {
    this.error = null;
    if (!this.voidMessage) {
      this.error = "Missing void message";
    }

    if (this.error) {
      return;
    }

    this.saving = true;
    await LabourRate.dispatch("voidLabourRate", {
      id: this.selectedVoid!.id,
      voided_comment: this.voidMessage,
    });
    this.error = null;
    if (
      this.$store.state.entities.labourRate?.error &&
      this.$store.state.entities.labourRate?.error.length
    ) {
      this.error =
        this.$store.state.entities.labourRate?.error[0].message.join(" ");
    }

    this.saving = false;

    this.$nextTick(() => this.toggleModal());

    this.$emit("on-void", this.selectedVoid!.id);
  }

  onTotalPageChange(val: number) {
    this.itemsPerPageSync = val;
  }

  onColumnFilterChange(val: LabourRateFilterQueryPayload) {
    this.columnFilterValueSync = val;
  }

  onSorterValueChange(val: { column: string; asc: boolean }) {
    this.sorterValueSync = val;
  }
}

export default LabourRateList;
