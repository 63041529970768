
import { CurrencyFormatMixin, HeMixin } from "@/mixins";
import { LabourRate, LabourRateInterface, userRepository } from "@/store/modules";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import { LabourRateForm } from "./form/LabourRateForm.vue";

@Component({
  components: { LabourRateForm }
})
export class LabourRateAdd extends mixins(HeMixin, CurrencyFormatMixin) {
  // part: LabourRate | null = null;
  rate: LabourRateInterface | null = null;

  mounted() {
    this.$nextTick(() => this.init());
  }

  async init() {
    const p = await LabourRate.new();
    this.rate = p.$toJson() as LabourRateInterface;
    // this.rate = p as LabourRate;
  }

  async onSubmit(e: Event) {
    e.preventDefault();
    if (!this.rate) {
      return;
    }
    let errorMessage: string | null = null, lastId = null;

    try {
      // this is a hack to set the current tech that added the labour rate
      // this SHOULD be done by the backend based on the auth token
      // because this method allows users to set any user id by hitting the API directly
      const { data: currentUser } = await userRepository.registered();
      const { firstname, lastname, ID } = currentUser?.profile || {};

      await LabourRate.dispatch("createLabourRate", { rate: { ...this.rate, techID: ID, tech_name: `${firstname} ${lastname}`.trim() } });

      lastId = this.getLastInsertedId();

      if (lastId <= 0) {
        throw 'Missing last inserted id';
      }

    } catch (err) {
      console.error('Error occured', err, this);
      if (typeof err === 'string') {
        errorMessage = err;
      }
    }

    if (!this.hasError() && !errorMessage) {
      this.$root.$bvToast.toast(`Added new rate: ${this.rate.rate}`, {
        title: 'Saving',
        variant: 'success'
      });

      this.$router.push(`/labour-rate/`);
      return;
    }

    this.$root.$bvToast.toast(errorMessage || `Failed to save rate`, {
      title: "Error",
      variant: "danger"
    });

  }

  getLastInsertedId() {
    return this.$store.state.entities.labourRate?.lastInsertedId;
  }

  hasError() {
    const errors = this.$store.state.entities.labourRate?.error;
    return !(!errors || !errors.length);
  }
}
export default LabourRateAdd;
