
import { Component, Vue, Watch, PropSync } from "vue-property-decorator";
import {
  TechnicianAllPayload,
  TechnicianFilterQueryPayload,
  Technician,
} from "@/store/modules";
import { filterEmptyQuery } from "@/utility";
import Multiselect from "vue-multiselect";
import { SortQueryPayload } from "@/store/modules";

type OptionProps = { label: string; value: number | null };

@Component({ components: { Multiselect } })
export default class TechnicianDropdown extends Vue {
  label: string = 'Technician';

  filter: TechnicianFilterQueryPayload = {};

  sort: SortQueryPayload = {
    sort: "firstname",
    order: "asc",
  };

  // @Model("change", { type: Array, default: () => [] })

  value: OptionProps[] = [];

  // @Model("change", { type: Number, required: false })
  mappedTags: Technician[] = [];

  options: Array<OptionProps> = [];

  optionIndex: Map<number, Technician> = new Map();



  @PropSync("selectedTech", { default: () => [], type: Array })
  selectedTechSync!: Technician[];

  debounceTimeout?: NodeJS.Timeout;

  searchString = "";
  // seaching = false;

  attributes() {
    return { ...this.$attrs, options: this.options }
  }

  mounted() {
    this.load();
  }

  load() {
    this.$nextTick(() => this.fetchRender(this.compilePayload()));
  }

  updateValue($event: number) {
    const opt = this.optionIndex.get($event);
    this.$emit('update:value', { id: $event, tech: opt })
  }

  // @Watch("selectedTech")
  // onSelectedTechSync() {
  //   // console.log(
  //   //   "selectedTech SYNC",
  //   //   this.selectedTechSync,
  //   //   this.selectedTechSync.length
  //   // );

  //   this.value = this.selectedTechSync.map((tech) => ({
  //     label: tech.name,
  //     value: tech.ID,
  //   }));
  // }

  async onSearchChange(query: string) {
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }

    this.debounceTimeout = setTimeout(async () => {
      // this.seaching = true;
      this.searchString = query;
      await this.load();
      // this.seaching = false;
    }, 1200);
  }

  async fetchRender(payload: TechnicianAllPayload) {
    await Technician.dispatch("all", { ...(payload || {}), limit: 99999 });
    this.updateOptions();
  }

  get loading() {
    // if (this.seaching) {
    //   return true;
    // }
    return !!Technician.store().state.entities.technician.fetching;
  }

  updateOptions(): Array<any> {
    const data = Technician.query().withAll().get();

    this.options = data.map((tech) => ({
      label: tech.name,
      value: tech.ID,
    }));

    this.options.unshift({
      value: null,
      label: "Choose a technician"
    });

    this.optionIndex = new Map(data.map((tech) => [tech.ID, tech]));

    return this.options;
  }

  compilePayload(
    merge: Partial<TechnicianAllPayload> = {}
  ): TechnicianAllPayload {
    return {
      ...this.sort,
      ...{ filter: this.compileFilter() },
      ...merge,
    };
  }

  compileFilter(): TechnicianAllPayload["filter"] {
    const f = filterEmptyQuery(this.filter);
    if (this.searchString) {
      f["firstname"] = this.searchString;
    }
    return { ...f };
  }

  get availableOptions() {
    return this.options;
  }

  // onSelect(selectedOption: OptionProps, id: number) {
  //   const { value } = selectedOption;
  //   const opt = this.optionIndex.get(value);
  //   if (opt) {
  //     // handler.change(`${opt.name}`);
  //     this.selectedTechSync.push(opt);
  //     this.$emit("tech-select", this.selectedTechSync);
  //   }
  // }

  // onRemove({ value }: OptionProps, id: number) {
  //   const i = this.selectedTechSync.findIndex((t) => t.ID === value);
  //   this.selectedTechSync.splice(i, 1);
  //   this.$emit("tech-select", this.selectedTechSync);
  // }
}
