
import { CurrencyFormatMixin, HeMixin } from "@/mixins";
import TechnicianDropdown from "@/pages/technician/filters/TechnicianDropdown.vue";
import { LabourRateInterface, Technician } from "@/store/modules";
import jsSHA from 'jssha';
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";



// see https://simonkollross.de/posts/vuejs-using-v-model-with-objects-for-custom-components
@Component({
  components: { TechnicianDropdown }
})
export class LabourRateForm extends mixins(HeMixin, CurrencyFormatMixin) {
  @Prop({ default: () => ({}) })
  rate!: LabourRateInterface;

  wasValidated = false;

  isPrestine = true;

  oriHash: string | null = null;

  mounted() {
    this.rate = {
      effective_at: this.now,
      ...this.rate
    }
    this.oriHash = this.hash(this.rate);
  }

  hash(p: LabourRateInterface) {
    const payload = JSON.stringify(p);
    const hash = new jsSHA("SHA-1", 'TEXT');
    hash.update(payload);
    return hash.getHash("HEX");
  }

  rateUpdate(key: string, val: number | string | null) {
    const p = { ...this.rate, [key]: val };
    this.isPrestine = this.oriHash === this.hash(p);
    this.$emit("input", p);
  }

  techUpdate({ tech, id }: { id: number, tech: Technician }) {
    this.rate.tech_name = `${tech.firstname || ''} ${tech.lastname || ''}`.trim();
    this.rateUpdate('techID', id);
  }

  get now() {
    const date = new Date();
    //offset in milliseconds. Credit https://stackoverflow.com/questions/10830357/javascript-toisostring-ignores-timezone-offset
    // @ts-ignore
    const localDate = new Date(date - date.getTimezoneOffset() * 60000);

    // Optionally remove second/millisecond if needed
    // @ts-ignore
    localDate.setSeconds(null);
    // @ts-ignore
    localDate.setMilliseconds(null);
    return localDate.toISOString().slice(0, -1);
  }

}
export default LabourRateForm;
