
import { CDataTableSortVal } from "@/@types";
import { CurrencyFormatMixin } from '@/mixins';
import {
  LabourRateFilterQueryPayload,
  labourRateRepository,
  PartRequestAllPayload,
  PartRequestFilterQueryPayload,
  SortQueryPayload
} from "@/store/modules";
import { filterEmptyQuery, queryFilterHasChange } from "@/utility";
import { mixins } from "vue-class-component";
import { Component, Watch } from "vue-property-decorator";
import LabourRate, { LabourRateInterface } from '../../store/modules/LabourRate/LabourRate';
import { LabourRateList } from "./LabourRateList.vue";

@Component({
  components: {
    LabourRateList,
  },
})
export default class LabourRateListHandler extends mixins(
  CurrencyFormatMixin
) {
  limit = 25;

  colFilter: LabourRateFilterQueryPayload = {};

  currentPage = 1;

  sort: SortQueryPayload = {
    sort: "created_at",
    order: "desc",
  };

  sortVal: CDataTableSortVal = {
    column: 'created_at', asc: false
  };

  currentRate: LabourRateInterface | null = null;

  @Watch("colFilter")
  onColFilterChange(
    val: PartRequestFilterQueryPayload | null,
    oldVal: PartRequestFilterQueryPayload | null
  ) {
    if (!val || !oldVal) {
      return;
    }

    if (queryFilterHasChange<PartRequestFilterQueryPayload>(val, oldVal)) {
      this.load();
    }
  }

  @Watch("limit")
  onLimitChange(val: number, oldVal: number) {
    if (val !== oldVal) {
      this.load();
    }
  }

  @Watch("sortVal")
  onSortValChange(val: CDataTableSortVal, oldVal: CDataTableSortVal) {
    this.sort = {
      sort: val.column || "",
      order: val.asc ? "asc" : "desc",
    };
    this.load();
  }

  onRefresh() {
    this.load();
  }

  mounted() {
    this.load();
  }

  load() {
    this.$nextTick(() => this.fetchRender(this.compilePayload()));
  }

  async fetchRender(payload: PartRequestAllPayload) {
    // since all will also populate the same store as current, it's easier to fetch the current rate manually.
    const response = await labourRateRepository.current();

    await LabourRate.dispatch("all", payload);

    if (response && response.status === 200 && response.data) {
      this.currentRate = response.data;
    }
  }

  get items(): Array<any> {
    const data = LabourRate.query()
      .orderBy(this.sort.sort || "created_at", this.sort.order)
      .withAll()
      .get();

    return data;
  }

  get loading(): boolean {
    return !!LabourRate.store().state.entities.labourRate.fetching;
  }

  get total() {
    return LabourRate.store().state.entities.labourRate.total;
  }

  get numPages(): number {
    const total = LabourRate.store().state.entities.labourRate.total;

    if (!total) {
      return 0;
    }

    return Math.max(Math.ceil(total / this.limit), 1);
  }

  onPageChange(page: number) {
    this.$nextTick(() =>
      this.fetchRender(
        this.compilePayload({
          offset: Math.floor(this.limit * (page - 1)),
        })
      )
    );
  }

  compilePayload(
    merge: Partial<PartRequestAllPayload> = {}
  ): PartRequestAllPayload {
    return {
      limit: this.limit,
      offset: Math.floor(this.limit * (this.currentPage - 1)),
      ...this.sort,
      ...{ filter: this.compileFilter() },
      ...merge,
    };
  }

  compileFilter(): PartRequestAllPayload["filter"] {
    const f = filterEmptyQuery(this.colFilter);
    const timeRange = [];
    if (this.colFilter.startDate || this.colFilter.endDate) {
      timeRange.push(this.colFilter.startDate || "");
      timeRange.push(
        this.colFilter.endDate || new Date(Date.now()).toISOString()
      );
      delete f.startDate;
      delete f.endDate;
    }

    if (timeRange.length) {
      f.timeRange = timeRange.join(",");
    }

    return { ...f };
  }
}
